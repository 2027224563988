<template>
  <div class="float_background" v-if="internalVisible" :style="{height:height,width:width}">
    <div class="float_config">
      <div class="header">
        <div></div>
        <div class="title">{{title}}</div>
        <div class="close" v-on:click="close()"><b-icon-x-lg/></div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    modelValue: String,
    title:{default:""},
    visible:{default:false}
  },
  data(){
    return {
      height: '100%',
      width:'100%',
      internalVisible: false,
    }
  },
  created() {
    window.addEventListener('resize', function () {
      this.resize();
    }.bind(this));
    this.resize();
    this.internalVisible = this.visible;
  },
  methods:{
    resize(){
      this.height = `${window.innerHeight}px`;
      this.width = `${window.innerWidth}px`;
    },
    close(){
      this.$emit('onclose');
      this.internalVisible = false;
    }
  },
  watch: {
    visible: {
      handler: function(val) {
        this.internalVisible = val
      },
      deep: true
    }
  },
}
</script>

<style scoped>
  .float_background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    align-items: center;
    justify-items: center;
  }
  .header{
    width: 100%;
    height: 35px;
    display: grid;
    grid-template-columns: 35px calc(100% - 70px) 35px;
  }
  .float_config{
    padding: 10px;
    background: #212121;
    border-radius: 9px;
  }
  .title{
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-weight: bold;
    text-align: center;
    color: var(--textColor);
  }
  .close{
    width: 28px;
    height: 28px;
    background: #101010;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-items: center;
    position: relative;
    left: 4px;
    color: #797979;
    font-size: 14px;
    cursor: pointer;
  }
</style>