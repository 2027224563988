<template>
  <div style="margin-top:10px;position: relative;">
    <div v-if="!chooseGame.visible">
      <div class="highlight_games">
        <div
         class="add_game_card"
         v-for="(game, i) in highlightsGames"
         v-bind:key="'game_'+game.id"
         :style="{'background-image': `url('${getImage(game.logo)}')`}"
        >
          <div class="action left" v-on:click="removeGame()" v-if="!isAdded(i)"><b-icon-trash/></div>
          <div class="action right" v-on:click="addGame(i)" v-if="!isAdded(i)"><b-icon-arrow-repeat/></div>
          <div
           v-if="isAdded(i)"
           class="add_game"
           v-on:click="addGame(i)"
           :class="{not_add:!canAdd(i)}"
          >
            <b-icon-plus-circle-dotted class="add" :class="{not_add:!canAdd(i)}" />
          </div>
        </div>
      </div>
      <CustomButton v-on:click="save()" :name="'SALVAR'"/>
    </div>
    <div class="choose_game_area" v-if="chooseGame.visible">
       <CustomInput :label="'Procurar'" :background="'var(--configsBC)'" @event="searchGame" :margin="'20px'"/>
       <div class="game_list custom_scroll">
         <div class="choose_game"
         v-for="game in games"
         v-bind:key="game.id"
         v-on:click="chooseThisGame(game)"
         :style="{'background-image': `url('${getGameImage(game)}')`}"
         ></div>
       </div>
       <CustomButton v-on:click="back()" :name="'VOLTAR'"/>
    </div>
    <Loading :size="'44px'" :color="'var(--textColor)'" class="loading" v-if="loadingGames"/>
  </div>
</template>

<script>

import CustomButton from "@/components/CustomButton.vue";
import CustomInput from "@/components/CustomInput.vue";
import Loading from "@/components/Loading.vue";
import settings from "../settings";
import utils from "../utils";

export default {
  components:{
    CustomButton,
    CustomInput,
    Loading
  },
  created(){
    this.getGames();
  },
  data(){
    return {
      games: [],
      gamesStorage: [],
      loadingGames: false,
      searchingGames: false,
      chooseGame:{
        index: 0,
        visible: false,
      },
      highlightsGamesMax: 6,
      highlightsGames: [

      ],
    }
  },
  methods:{
    async getGames() {
      this.loadingGames = true;
      let res = await this.axios.post(
        settings.APIRoutes.getGames() + "/1",
        { token: utils.getCookie(settings.cookieAuthToken) }
      );
      this.loadingGames = false;
      if (res.status == 200) {
        res.data.games.forEach((item) => {
          this.games.push(item);
        });
        res.data.highlights.forEach((item) => {
          this.highlightsGames.push({id:item.id, logo:this.getGameImage(item)});
        });
        let leftHighlights = this.highlightsGamesMax - this.highlightsGames.length;
        for (let i = 0; i < leftHighlights; i++) {
          this.highlightsGames.push({id:0,logo:""});
        }
      } else {
        this.notifyError("error ao carregar a lista de jogos");
      }
    },
    async searchGame(e) {
      if (e.value.length > 0) {
        if (!this.searchingGames) {
          this.gamesStorage = utils.safeCopyObject(this.games);
        }
        this.games = [];
        this.searchingGames = true;
        this.loadingGames = true;
        let res = await this.axios.post(settings.APIRoutes.searchGames(), {
          token: utils.getCookie(settings.cookieAuthToken),
          query: e.value,
        });
        this.loadingGames = false;
        if (res) {
          this.games = res.data.games;
          if (this.games.length == 0) {
            this.notifyError("nada foi encontrado!");
          }
        } else {
          this.notifyError("error ao procurar pelo game");
        }
      } else {
        setTimeout(() => {
          this.searchingGames = false;
          if (this.gamesStorage.length > 0) {
            this.games = utils.safeCopyObject(this.gamesStorage);
            this.gamesStorage = [];
          }
        }, 300);
      }
    },
    async save(){
      let games = utils.safeCopyObject(this.highlightsGames);
      games.forEach((e,i)=>{
        delete games[i].logo;
      });
      let res = await this.axios.post(settings.APIRoutes.saveHighlights(), {
        token: utils.getCookie(settings.cookieAuthToken),
        games: JSON.stringify(games),
      });
      if(res.data.status){
        this.notifySuccess("JOGOS EM DESTAQUE SALVO");
      }
    },
    addGame(index){
      if(this.gamesStorage.length > 0 && this.games.length == 0){
        this.games = utils.safeCopyObject(this.gamesStorage);
      }
      this.chooseGame.visible = true;
      this.chooseGame.index = index;
    },
    chooseThisGame(game){
      let added = false;
      this.highlightsGames.forEach((e)=>{
        if(e.id == game.id){
          added = true;
        }
      });
      if(added){
        this.notifyError("ESTE JOGO JÁ FOI ADICIONADO!");
        return;
      }
      this.highlightsGames[this.chooseGame.index].id = game.id;
      this.highlightsGames[this.chooseGame.index].logo = this.getGameImage(game, false);
      this.back();
    },
    removeGame(index){
      this.highlightsGames.splice(index,1);
      this.highlightsGames.push({id:0,logo:""});
    },
    back(){
      this.chooseGame.visible = false;
      this.chooseGame.index = 0;
    },
    getGameImage(game, withoutHost){
      let image = "";
      for (var [key] of Object.entries(game.langs)) {
        if(game.langs[key].logo.length > 0){
          image = game.langs[key].logo;
        }
      }
      if(withoutHost){
        return image;
      }else{
        return `${settings.APIHost}/image/${image}`;
      }
    },
    getImage(name) {
      if (name == "" || name.search('http') >= 0) {
        return name;
      } else {
        return `${settings.APIHost}/image/${name}`;
      }
    },
    canAdd(index){
      let addedGames = this.highlightsGames.filter(e => e.id > 0).length;
      if(index == addedGames){
        return true
      }else{
        return false;
      }
    },
    isAdded(index){
      let addedGames = this.highlightsGames.filter(e => e.id > 0).length;
      if(index >= addedGames){
        return true
      }else{
        return false;
      }
    },
    notifyError(msg) {
      this.$toast.open({
        message: msg,
        duration: 5 * 1000,
        position: "bottom",
        type: "error",
      });
    },
    notifySuccess(msg) {
      this.$toast.open({
        message: msg,
        duration: 5 * 1000,
        position: "bottom",
        type: "success",
      });
    },
  }
}
</script>

<style scoped>
  .highlight_games{
    position: relative;
    width: 330px;
    height: 350px;
    display: grid;
    grid-template-columns: calc(33.3% - 6.7px) calc(33.3% - 6.6px) calc(33.3% - 6.7px);
    grid-template-rows: calc(50% - 5px) calc(50% - 5px);
    grid-gap: 10px;
  }
  .loading{
    position: absolute;
    left: calc(50% - 22px);
    top:50px;
  }
  .add_game_card{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--background1);
    border-radius: 8px;
    background-size: 100% 100%;
  }
  .add {
    width: 50px;
    height: 50px;
    color: #7d7d7d;
  }
  .not_add{
    color: #424242;
    cursor: initial !important;
  }
  .add_game{
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor:pointer;
  }
  .choose_game_area{
    width: 330px;
    height: calc(350px + 33px);
  }
  .game_list{
    width: 100%;
    height: 291px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: calc(33.3% - 6.7px) calc(33.3% - 6.6px) calc(33.3% - 6.7px);
    column-gap: 10px;
    overflow-y: auto;
  }
  .choose_game{
    width: 100%;
    height: 170px;
    border-radius: 8px;
    background: var(--background1);
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .action{
    width: 28px;
    height: 28px;
    background: var(--background0);
    position: absolute;
    top: 5px;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-items: center;
    color:var(--textColor);
    cursor: pointer;
  }
  .left{
    left: 5px;
  }
  .right{
    right: 5px;
  }
</style>