<template>
  <div class="card_division margin_left">
    <div>
      <div class="cards_game vertical_division_card">
          <div>
            <div class="bottom_card_some_title" style="position:relative;top:3px;">ONLINE</div>
            <div class="bottom_card_big_center_numbers"><b-icon-people-fill style="position:relative;top:5px;"/> 
              <NumberAnimation
                ref="number1"
                :from="500"
                :to="online"
                :duration="2"
                :format="formatNumber"
                autoplay
                easing="linear"
              />
            </div>
            <div style="height:18px;"></div>
            <div class="bottom_card_some_title" style="position:relative;top:3px;">JOGANDO</div>
            <div class="bottom_card_big_center_numbers"><b-icon-controller style="position:relative;top:5px;"/> 
              <NumberAnimation
                ref="number1"
                :from="350"
                :to="playing"
                :duration="3"
                :format="formatNumber"
                autoplay
                easing="linear"
              />
            </div>
          </div>
      </div>
    </div>
    <div>
      <div class="cards_game vertical_division_card margin_top">
          <div>
            <div class="bottom_card_some_title" style="position:relative;top:3px;">NOTIFICAVEIS</div>
            <div style="height:5px;"></div>
            <div class="bottom_card_big_center_numbers"><b-icon-send-check-fill style="position:relative;top:5px; font-size:23px;"/>
              <NumberAnimation
                ref="number1"
                :from="12000"
                :to="notifiable"
                :duration="5"
                :format="formatNumber"
                autoplay
                easing="linear"
              />
            </div>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import settings from "../settings";
import utils from "../utils";
import NumberAnimation from "vue-number-animation";
export default {
  components: {
    NumberAnimation
  },
  setup() {},
  data() {
    return {
      online: 0,
      playing: 0,
      notifiable: 0,
    };
  },
  mounted() {
    this.getUsersStatus();
    this.getNotifiable();
  },
  methods: {
    formatNumber(value) {
      return ` ${Math.round(value)}`;
    },
    async getUsersStatus(){
      let res = await this.axios.get(settings.APIRoutes.getUsersStatus());
      if(res){
        this.online = res.data.online;
        this.playing = res.data.playing;
      }
      setTimeout(() => {
        this.getUsersStatus()
      }, 4.5*1000);
    },
    async getNotifiable(){
      let res = await this.axios.post(settings.APIRoutes.getNotifiable(),{
        token: utils.getCookie(settings.cookieAuthToken),
        });
      if(res){
        this.notifiable = res.data.total;
      }
      setTimeout(() => {
        this.getNotifiable()
      }, 44*1000);
    }
  }
}
</script>

<style scoped>
.card_division{
  height: 100%;
  min-width: 150px;
  display: grid;
  grid-template-rows: 65% 35%;
}

.vertical_division_card{
  padding: 0px !important;
  width: 100% !important;
  height: calc(100% - 5px) !important;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.online_uses_p{
  position: relative;
  display: block;
}

.bottom_card_big_center_numbers{
  margin: 0px;
  font-weight: bolder;
  font-size: 25px;
  color: var(--theme)
}

.bottom_card_some_title{
  font-size: 17px;
  color: var(--textColor);
  font-weight: bold;
}
</style>