const settings = {
  cookieAuthToken: "auth_token",
  APIHost: "https://psx.igamesapi.dev/api",
  //APIHost: "http://localhost:3003/api",
  APIRoutes: {
    login: () => settings.APIHost + "/panel/login",
    auth: () => settings.APIHost + "/panel/manager/auth",
    getCategories: () => settings.APIHost + "/app/categories",
    uploadImages: () => settings.APIHost + "/panel/manager/upload-image",
    addCategory: () => settings.APIHost + "/panel/manager/add-category",
    saveGame: () => settings.APIHost + "/panel/manager/save-game",
    getGames: () => settings.APIHost + "/panel/manager/games",
    searchGames: () => settings.APIHost + "/panel/manager/search",
    saveHighlights: () => settings.APIHost + "/panel/manager/save-highlights",
    updateCat: () => settings.APIHost + "/panel/manager/update-category",
    deleteCat: () => settings.APIHost + "/panel/manager/delete-category",
    saveConfigs: () => settings.APIHost + "/panel/manager/save-configs",
    getConfigs: () => settings.APIHost + "/panel/manager/configs",
    deleteGame: () => settings.APIHost + "/panel/manager/delete-game",
    getUsersStatistics: () =>
      settings.APIHost + "/panel/manager/users-status-statistics",
    getUsersStatus: () => settings.APIHost + "/app/users-status",
    getNotifiable: () => settings.APIHost + "/panel/manager/get-notifiable",
    getSearches: () => settings.APIHost + "/panel/manager/searches-in-app",
    getReviews: () => settings.APIHost + "/panel/manager/last-reviews",
  },
};

export default settings;
