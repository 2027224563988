import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import axios from "axios";
import VueAxios from "vue-axios";
import VueToast from "vue-toast-notification";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import VueUploadComponent from "vue-upload-component";
import "vue-toast-notification/dist/theme-sugar.css";
import VueApexCharts from "vue3-apexcharts";

const app = createApp(App).use(router);
app.component("file-upload", VueUploadComponent);
app.use(VueAxios, axios);
app.use(VueToast);
app.use(VueApexCharts);
app.use(BootstrapIconsPlugin);

app.provide("axios", app.config.globalProperties.axios);
app.mount("#app");
