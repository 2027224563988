<template>
  <apexchart type="line" ref="users_statistics" height="250" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import settings from "../settings";
import utils from "../utils";
import apexchart from "vue3-apexcharts";
export default {
  name: 'CustomInput',
  components: {
    apexchart,
  },
  data(){
    return {
      series: [
        {
          name: "Onlines",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Jogando",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
      chartOptions: {
        chart: {
          height: 250,
          type: "line",
          foreColor: "#fff",
          toolbar: {
            show: false,
          },
        },
        colors: ["#29ff7e", "#000"],
        fill: {
          colors: ["#ffffff"],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "11px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: ["#29ff7e", "#fff"],
          },
          background: {
            enabled: true,
            foreColor: ["#29ff7e", "#29ff7e"],
            padding: 3,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#000",
            opacity: 0.9,
          },
        },
        stroke: {
          curve: "monotoneCubic",
        },
        title: {
          text: "Usuários",
          align: "left",
          color: "#ffffff",
        },
        markers: {
          size: 0,
          colors: ["#ffffff", "#ffffff", "#ffffff"],
        },
        xaxis: {
          categories: ["00h", "00h", "00h", "00h", "00h", "00h", "00h"],
        },

        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    }
  },
  mounted() {
    this.getUsersStatistics();
  },
  methods: {
    async getUsersStatistics() {
      let res = await this.axios.post(settings.APIRoutes.getUsersStatistics(), {
        token: utils.getCookie(settings.cookieAuthToken),
      });
      if (res) {
        this.series[0].data = [];
        this.series[1].data = [];
        console.log(this.chartOptions.xaxis.categories);
        this.chartOptions.xaxis.categories = [];
        for (let i = res.data.length - 1; i >= 0; i--) {
          let time = res.data[i].time.split(" ")[1];
          console.log(time);
          this.chartOptions.xaxis.categories.push(time + "h");
          this.series[0].data.push(res.data[i].onlines);
          this.series[1].data.push(res.data[i].playing);
        }
        this.$refs.users_statistics.updateOptions({
          xaxis: {
            categories: this.chartOptions.xaxis.categories, //ie ["a","b","c","d"]
          },
        });
      }
    },
  },
}
</script>