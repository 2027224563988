<template>
  <div class="input_field_collector" :class="{highlight:focus}" :style="{'margin-top':margin, 'height':height}">
    <span class="label_collector" :class="{highlight:focus}" :style="{'background':background}">
      <div class="label_bottom_hide_collector" :style="{'background':background}"></div>
      <span class="label_text_collector" :class="{highlight:focus}">{{label}}</span>
    </span>
    <div class="overflow">
      <textarea
        @input="onEvent"
        v-model="content"
        @focus="focus = true"
        @blur="focus = false"
        class="input_collector collector_url_redirect custom_scroll"
        :type="type"
        spellcheck="false"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomTextArea',
  data(){
    return {
      content:this.modelValue,
      focus: false,
    }
  },
  props: {
    modelValue: String,
    label: String,
    type:{
      type:String,
      default: "text"
    },
    background:{
      type:String,
      default: "#fff"
    },
    margin:{
      type:String,
      default: "20px"
    },
    height:{
      type:String,
      default: "50px"
    },
  },
  emits: ['update:modelValue', 'event'],
  methods: {
    onEvent(e) {
      this.$emit('event', e);
      this.$emit('update:modelValue', this.content);
    },
  },
  watch: {
    modelValue: {
      handler: function(val) {
        this.content = val
        this.$emit('update:modelValue', this.content);
      },
      deep: true
    }
  },
}
</script>

<style scoped>
.overflow{
  overflow: hidden;
  border-radius: 19px;
  width: 100%;
  height: 100%;
  position: relative;
}
div.input_field_collector {
  width: 100%;
  position: relative;
  height: 38px;
  border-radius: 19px;
  margin-top: 20px;
  box-sizing: border-box;
  border: 2px solid var(--textColor);
}
span.label_collector {
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  position: absolute;
  padding: 0px 5px 0px 5px;
  background: #fff;
  color: var(--textColor);
  font-weight: bold;
  font-size: 12px;
  top: -11px;
  left: 22px;
  border: 2px solid var(--textColor);
  border-radius: 14px;
  user-select: none;
}
div.label_bottom_hide_collector {
  display: block;
  position: absolute;
  width: calc(100% + 4px);
  height: 10px;
  background: #fff;
  top: 9px;
  left: -2px;
}
span.label_text_collector {
  display: inline-block;
  position: relative;
  user-select: none;
}
.input_collector {
  resize: none;
  width: 100%;
  height: 100%;
  border: 0px;
  box-sizing: border-box;
  padding: 6px 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--textColor);
  border-radius: 19px;
  outline: none;
  background: transparent;
  font-size: 17px;
  position: absolute;
  z-index: 11;
  box-shadow: none;
  -webkit-appearance: none;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 10px;
  padding: 0;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 10px;
  padding: 0;
}

input[type="color"] {
  margin: 0px;
  border: none;
  padding: 8px 10px;
  border-radius: 10px;
  cursor: pointer;
}
.highlight{
  border-color: var(--themeDark) !important;
  color:var(--themeDark);
}
</style>