<template>
  <div class="loading" :style="{'width':size, 'height':size}">
    <div class="lds-ripple">
      <div :style="{'border-color':color}"></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    color:{
      type:String,
      default:"#2e2e2e",
    },
    size:{
      type:String,
      default:"20px",
    }
  }
}
</script>

<style scoped>
  .loading{
    display: inline-block;
    position: relative;
  }
  .lds-ripple {
    display: grid;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: center;
  }
  .lds-ripple div {
    position: absolute;
    border: 2px solid #2e2e2e;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
</style>