<template>
  <div class="margin_left cards_game searches_in_app_container">
    <div class="card_game_header">
      <div class="card_game_name">
          Pesquisas Recentes
      </div>
    </div>
    <div class="content_container">
      <div class="side center_margin custom_scroll">
        <div v-for="search in lastSearches" v-bind:key="search.id" class="item_lits">{{search.content}}</div>
      </div>
      <div class="side center_margin custom_scroll right">
        <div class="item_lits" v-for="search in lastSameSearches" v-bind:key="search.id">
          <div class="with_count">{{search.content}}</div>
          <div>
            <div class="count">{{search.count}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import settings from "../settings";
import utils from "../utils";
export default {
  components: {},
  setup() {},
  data() {
    return {
      lastSearches: [],
      lastSameSearches: [],
    };
  },
  mounted() {
    this.getSearches();
  },
  methods: {
    async getSearches(){
      let res = await this.axios.post(settings.APIRoutes.getSearches(),{
        token: utils.getCookie(settings.cookieAuthToken),
        });
      if(res){
        this.lastSearches = res.data.lastSearches;
        this.lastSameSearches = res.data.lastSameSearches.sort((a, b) => b.count - a.count);
      }
      setTimeout(() => {
        this.getSearches()
      }, 15*1000);
    }
  }
}
</script>


<style scoped>
.searches_in_app_container{
  width: 360px !important;
}
.content_container{
  display: grid;
  grid-template-columns: 50% 50%;
  height: calc(100% - 35px);
  margin-top: 5px;
}
.side{
  background: var(--background0);
  height: 100%;
  border-radius: 8px;
  overflow-y: scroll;
}
.center_margin{
  width: calc(100% - 5px);
}
.right{
  position: relative;
  float: right;
  margin-left: 5px;
}
.item_lits{
  font-size: 11.8px;
  padding: 6px 5px;
  color:var(--textColor);
  background: var(--background);
  margin-top: 8.6px;
  margin-left: 8px;
  margin-right: 2px;
  border-radius: 10px;
  display: flex;
}

.item_lits .with_count{
  width: calc(100% - 30px);
}

.item_lits .count{
  width: 30px;
  background: var(--theme);
  color: var(--background);
  text-align: center;
  font-weight: bold;
  border-radius: 6px;
}

</style>