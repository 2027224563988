import CustomInput from "@/components/CustomInput.vue";
import CustomButton from "@/components/CustomButton.vue";
import settings from "../settings";
import utils from "../utils";
import Loading from "@/components/Loading.vue";

export default {
  created() {
    if (utils.getCookie(settings.cookieAuthToken)) {
      this.axios
        .post(settings.APIRoutes.auth(), {
          token: utils.getCookie(settings.cookieAuthToken),
        })
        .then((response) => {
          if (!response.data.status || response.data.status == null) {
            this.available = true;
          } else {
            this.$router.push({ name: "panel" });
          }
        });
    } else {
      this.available = true;
    }
  },
  data() {
    return {
      available: false,
      loginButtonActive: false,
      loading: false,
      user: "",
      pass: "",
    };
  },
  methods: {
    async onLogin() {
      this.loading = true;
      this.loginButtonActive = false;
      let response = await this.axios.post(settings.APIRoutes.login(), {
        user: this.user,
        pass: this.pass,
      });
      if (response.data.success) {
        utils.setCookie(settings.cookieAuthToken, response.data.token, 365);
        this.$router.push({ name: "panel" });
      } else {
        this.$toast.open({
          message: "usuário e/ou senha incorretas!",
          duration: 3 * 1000,
          position: "bottom",
          type: "error",
        });
        this.loading = false;
        this.loginButtonActive = true;
      }
    },
    validateLogin() {
      if (this.user.length >= 6 && this.pass.length >= 6 && !this.loading) {
        this.loginButtonActive = true;
      } else {
        this.loginButtonActive = false;
      }
    },
  },
  components: {
    CustomInput,
    CustomButton,
    Loading,
  },
  watch: {
    user() {
      this.validateLogin();
    },
    pass() {
      this.validateLogin();
    },
  },
};
