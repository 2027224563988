<template>
  <div class="main_container">
    <Loading v-if="!available" :size="'46px'"/>
    <div class="login_container" v-if="available">
      <p class="login_title">ENTRAR</p>
      <CustomInput v-model="user" label="USUÁRIO" :background="'var(--background)'" />
      <CustomInput v-model="pass" :type="'password'" label="SENHA" :background="'var(--background)'"/>
      <CustomButton name="ENTRAR" @ontap="onLogin" :active="loginButtonActive" :loading="loading"/>
    </div>
  </div>
</template>

<script src="@/controllers/loginController.js"></script>


<style scoped>
  .main_container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
  }
  .login_container{width: 280px;}
  .login_title{
    text-align: center;
    font-size: 20px;
    color:var(--textColor);
  }
</style>