<template>
  <label class="wrapper flex items-center">
    {{label}}
    <input class="checkbox" type="checkbox" :checked="checked" :value="value" @change="updateInput"/>
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    "value": { default: 0 },
    "modelValue": { default: "" },
    "label": { type: String},
    "trueValue": { default: true },
    "falseValue": { default: false },
    checked:{default: false,}
  },
  mounted(){
    this.isChecked = this.checked;
  },
  watch: {
    checked: {
      handler: function(val) {
        this.isChecked = val;
      },
      deep: true
    }
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
        this.$emit('chg', {isChecked:newValue, value:this.value})
      } else {
        let v = isChecked ? this.trueValue : this.falseValue
        this.$emit('chg', {isChecked:v,value:this.value})
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
/* Customize the label (the wrapper) */
.wrapper {
  display: block;
  position: relative;
  padding-left: 25px;
  height: 15px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
}
/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 7px;
    /* background-color: #eee; */
    border: 2px solid var(--theme);
}
/* On mouse-over, add a grey background color */

/* When the checkbox is checked, add a blue background */
.wrapper input:checked ~ .checkmark {
  background-color: var(--theme);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.wrapper .checkmark:after {
  left: 2px;
    top: 0px;
    width: 7px;
    height: 8px;
    border: solid var(--background);
    border-width: 0 3px 3px 0;
    transform: rotate(24deg);
}
</style>