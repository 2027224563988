import CustomInput from "@/components/CustomInput.vue";
import CustomTextArea from "@/components/CustomTextArea.vue";
import CustomButton from "@/components/CustomButton.vue";
import CheckBox from "@/components/CheckBox.vue";
import FileUpload from "vue-upload-component/dist/vue-upload-component.part.js";
import Loading from "@/components/Loading.vue";
import FloatSettings from "@/components/FloatSettings.vue";
import HighlightsGames from "@/components/HighlightsGames.vue";
import UsersStatistics from "@/components/UsersStatistics.vue";
import OnlinePlayingStatus from "@/components/OnlinePlayingStatus.vue";
import SearchesInApp from "@/components/SearchesInApp.vue";
import GamesReviews from "@/components/GamesReviews.vue";
import settings from "../settings";
import utils from "../utils";
import date from "date-and-time";

export default {
  components: {
    CustomInput,
    CustomTextArea,
    CustomButton,
    CheckBox,
    FileUpload,
    Loading,
    FloatSettings,
    HighlightsGames,
    UsersStatistics,
    OnlinePlayingStatus,
    SearchesInApp,
    GamesReviews,
  },

  created() {
    if (utils.getCookie(settings.cookieAuthToken)) {
      this.axios
        .post(settings.APIRoutes.auth(), {
          token: utils.getCookie(settings.cookieAuthToken),
        })
        .then((response) => {
          if (!response.data.status || response.data.status == null) {
            this.$router.push({ name: "login" });
          } else {
            this.available = true;
          }
        });
    } else {
      this.$router.push({ name: "login" });
    }
    if (utils.getCookie(settings.cookieAuthToken)) {
      document.title = `${this.panelName}  ${document.title}`;
      this.getGames();
      this.getCategories();
      //this.components.UsersStatistics.getUsersStatistics();
      this.newGameData = this.safeCopyObject(this.gameEdit);
      this.gameByLang = this.safeCopyObject(this.gameEdit.langs.pt);
    }
  },
  data() {
    return {
      panelName: "PSX",
      highlightsGamesVisible: false,
      newGameData: {},
      gameByLang: {},
      defaultLanguage: "pt",
      available: false,
      games: [],
      pages: 1,
      nextPage: 1,
      loadingGames: false,
      searchingGames: false,
      configsVisible: false,
      editCategoryVisible: false,
      gameLangSuggestionVisible: false,
      gamesStorage: [],
      deleteGame: {
        visible: false,
        game: {},
      },
      categoryEdit: {
        id: 0,
        name: "",
      },
      gameSettings: {
        saveButtonActive: true,
        addCategoryVisible: false,
        languages: {
          pt: "pt",
          en: "en",
          es: "es",
        },
        categories: [],
        uploadingLogo: false,
        uploadingPrints: false,
        currentLanguage: "pt",
        newCategory: "",
      },
      gameEdit: {
        id: 0,
        published: true,
        notify: true,
        categories: [],
        date: date.format(new Date(), "YYYY-MM-DD HH:mm:ss"),
        views: 0,
        langs: {
          pt: {
            title: "",
            description: "",
            download_url: "",
            video: "",
            logo: "",
            prints: [],
            year: "",
            size: "",
            game_lang: "en",
          },
        },
      },
    };
  },
  watch: {
    "categoryEdit.name": function () {
      this.categoryEdit.name = this.categoryEdit.name.toUpperCase();
    },
    "gameEdit.langs.pt.year": function () {
      if (this.gameEdit.langs.en) {
        this.gameEdit.langs.en.year = this.gameEdit.langs.pt.year;
      }
      if (this.gameEdit.langs.es) {
        this.gameEdit.langs.es.year = this.gameEdit.langs.pt.year;
      }
    },
    "gameEdit.langs.pt.size": function () {
      if (this.gameEdit.langs.en) {
        this.gameEdit.langs.en.size = this.gameEdit.langs.pt.size;
      }
      if (this.gameEdit.langs.es) {
        this.gameEdit.langs.es.size = this.gameEdit.langs.pt.size;
      }
    },
  },
  methods: {
    async getGames() {
      this.loadingGames = true;
      let res = await this.axios.post(
        settings.APIRoutes.getGames() + "/" + this.nextPage,
        { token: utils.getCookie(settings.cookieAuthToken) }
      );
      this.loadingGames = false;
      if (res.status == 200) {
        res.data.games.forEach((item) => {
          this.games.push(item);
        });
        this.nextPage = res.data.next_page;
        this.pages = res.data.pages;
      } else {
        this.notifyError("error ao carregar a lista de jogos");
      }
    },
    async searchGame(e) {
      if (e.value.length > 0) {
        if (!this.searchingGames) {
          this.gamesStorage = this.safeCopyObject(this.games);
        }
        this.games = [];
        this.searchingGames = true;
        this.loadingGames = true;
        let res = await this.axios.post(settings.APIRoutes.searchGames(), {
          token: utils.getCookie(settings.cookieAuthToken),
          query: e.value,
        });
        this.loadingGames = false;
        if (res) {
          this.games = res.data.games;
          if (this.games.length == 0) {
            this.notifyError("nada foi encontrado!");
          }
        } else {
          this.notifyError("error ao procurar pelo game");
        }
      } else {
        setTimeout(() => {
          this.searchingGames = false;
          if (this.gamesStorage.length > 0) {
            this.games = this.safeCopyObject(this.gamesStorage);
            this.gamesStorage = [];
          }
        }, 300);
      }
    },

    selectGame(id) {
      if (this.gameSettings.saveButtonActive) {
        let index = this.games.findIndex((e) => e.id == id);
        if (index >= 0) {
          let game = this.games[index];
          if (game.langs.pt) {
            this.gameSettings.currentLanguage = "pt";
          } else if (game.langs.en) {
            this.gameSettings.currentLanguage = "en";
          } else if (game.langs.es) {
            this.gameSettings.currentLanguage = "es";
          }
          this.gameEdit = game;
          this.gameSettings.categories.forEach((e, i) => {
            this.gameSettings.categories[i].checked = false;
          });
          game.categories.forEach((e) => {
            let catIndex = this.gameSettings.categories.findIndex(
              (item) => item.id == e
            );
            if (catIndex >= 0) {
              this.gameSettings.categories[catIndex].checked = true;
            }
          });
        }
      }
    },
    getGameTitle(game) {
      if (game.langs.pt) {
        return game.langs.pt.title ?? "";
      } else if (game.langs.en) {
        return game.langs.en.title ?? "";
      } else if (game.langs.es) {
        return game.langs.es.title ?? "";
      }
    },
    editCategory(category) {
      this.editCategoryVisible = true;
      this.categoryEdit = category;
    },
    changePublished(val) {
      console.log(val.isChecked);
      this.gameEdit.published = val.isChecked;
    },
    changeNotify(val) {
      console.log(val.isChecked);
      this.gameEdit.notify = val.isChecked;
    },
    async updateCategory() {
      let res = await this.axios.post(settings.APIRoutes.updateCat(), {
        token: utils.getCookie(settings.cookieAuthToken),
        id: this.categoryEdit.id,
        name: this.categoryEdit.name,
      });
      if (res.data.status) {
        this.editCategoryVisible = false;
        this.notifySuccess("CATEGORIA ATUALIZADA!");
      } else {
        this.notifySuccess("ERRO AO SALVAR! TENTE NOVAMENTE");
      }
    },
    async deleteCategory() {
      let res = await this.axios.post(settings.APIRoutes.deleteCat(), {
        token: utils.getCookie(settings.cookieAuthToken),
        id: this.categoryEdit.id,
      });
      if (res.data.status) {
        let index = this.gameSettings.categories.findIndex(
          (e) => e.id == this.categoryEdit.id
        );
        if (index >= 0) {
          this.gameSettings.categories.splice(index, 1);
        }
        this.editCategoryVisible = false;
        this.notifySuccess("CATEGORIA EXLUIDA!");
      } else {
        this.notifySuccess("ERRO AO EXCLUIR! TENTE NOVAMENTE");
      }
    },
    addNewGame() {
      this.gameEdit = this.safeCopyObject(this.newGameData);
      this.gameSettings.currentLanguage = this.safeCopyObject(
        this.defaultLanguage
      );
      this.gameSettings.categories.forEach((e, i) => {
        this.gameSettings.categories[i].checked = false;
      });
    },
    getGameEdit() {
      return this.gameEdit.langs[this.gameSettings.currentLanguage];
    },
    langIsOk(lang) {
      let game = this.safeCopyObject(this.gameEdit);
      let gameLang = game.langs[lang];
      if (gameLang) {
        if (gameLang.title.length > 0 && gameLang.download_url.length > 0) {
          return true;
        }
      }
      return false;
    },
    openDeleteGameDialog(game) {
      this.deleteGame.visible = true;
      this.deleteGame.game = game;
    },
    getImage(name) {
      if (name == "") {
        return name;
      } else {
        return `${settings.APIHost}/image/${name}`;
      }
    },
    safeCopyObject(source) {
      return JSON.parse(JSON.stringify(source));
    },
    isThisLanguage(lang) {
      return this.gameSettings.currentLanguage == lang;
    },
    async deleteGameConfirmed() {
      this.addNewGame();
      let index = this.games.findIndex((e) => e.id == this.deleteGame.game.id);
      if (index >= 0) {
        this.games.splice(index, 1);
        this.deleteGame.visible = false;
        let res = await this.axios.post(settings.APIRoutes.deleteGame(), {
          token: utils.getCookie(settings.cookieAuthToken),
          id: this.deleteGame.game.id,
        });
        if (res.data.status) {
          this.notifySuccess("GAME EXLUIDO!");
        } else {
          this.notifyError("ERRO AO EXCLUIR GAME! TENTE NOVAMENTE");
        }
      }
    },
    changeGameLanguage(newLang) {
      if (this.gameEdit.langs[newLang] == null) {
        this.gameEdit.langs[newLang] = this.safeCopyObject(this.gameByLang);
        this.gameEdit.langs[newLang].prints =
          this.gameEdit.langs[this.defaultLanguage].prints;
        this.gameEdit.langs[newLang].logo =
          this.gameEdit.langs[this.defaultLanguage].logo;
        this.gameEdit.langs[newLang].title =
          this.gameEdit.langs[this.defaultLanguage].title;
        this.gameEdit.langs[newLang].year =
          this.gameEdit.langs[this.defaultLanguage].year;
        this.gameEdit.langs[newLang].size =
          this.gameEdit.langs[this.defaultLanguage].size;
        this.gameEdit.langs[newLang].game_lang =
          this.gameEdit.langs[this.defaultLanguage].game_lang;
        this.gameEdit.langs[newLang].video =
          this.gameEdit.langs[this.defaultLanguage].video;
      }
      this.gameSettings.currentLanguage = newLang;
    },
    deleteGamePrint(index) {
      this.gameEdit.langs[this.gameSettings.currentLanguage].prints.splice(
        index,
        1
      );
    },
    openConfigs() {
      this.configsVisible = true;
    },
    isFilesImage(files) {
      const acceptedImageTypes = ["image/jpeg", "image/png"];
      let isAllImages = true;
      Array.from(files).forEach((file) => {
        if (!acceptedImageTypes.includes(file.type)) {
          isAllImages = false;
        }
      });
      return isAllImages;
    },
    selectCategory(data) {
      let index = this.gameEdit.categories.findIndex((e) => e == data.value);
      if (index < 0) {
        this.gameEdit.categories.push(data.value);
      } else {
        delete this.gameEdit.categories.splice(index, 1);
      }
    },
    async getCategories() {
      let res = await this.axios.get(settings.APIRoutes.getCategories(), {
        category: this.gameSettings.newCategory.toUpperCase(),
        token: utils.getCookie(settings.cookieAuthToken),
      });
      if (res.status == 200) {
        this.gameSettings.categories = res.data;
        this.gameSettings.categories.forEach((e, i) => {
          this.gameSettings.categories[i].checked = false;
        });
      }
    },
    async addCategory() {
      if (
        this.gameSettings.categories.findIndex(
          (e) => e.name == this.gameSettings.newCategory.toUpperCase()
        ) >= 0
      ) {
        this.notifyError("essa categoria já existe!");
        return;
      }
      this.gameSettings.addCategoryVisible = false;
      this.$toast.open({
        message: "salvando categoria",
        duration: 3 * 1000,
        position: "bottom",
        type: "info",
      });
      let res = await this.axios.post(settings.APIRoutes.addCategory(), {
        category: this.gameSettings.newCategory.toUpperCase(),
        token: utils.getCookie(settings.cookieAuthToken),
      });
      if (res.status == 200) {
        if (res.data.status) {
          this.notifySuccess("categoria salva!");
          this.gameSettings.categories.push({
            id: res.data.category_id,
            name: this.gameSettings.newCategory.toUpperCase(),
          });
          this.gameSettings.newCategory = "";
        } else {
          this.notifyError("error ao salvar a categoria! tente novamente");
        }
      } else {
        this.notifyError("error ao salvar a categoria! tente novamente");
      }
    },
    async saveGame() {
      let pass = true;
      let game = this.safeCopyObject(this.gameEdit);
      Object.entries(game.langs).forEach(([key, value]) => {
        if (value.title.length == 0) {
          delete game.langs[key];
        }
      });
      let hasGameLang = Object.keys(game.langs).length > 0 ? true : false;
      if (!hasGameLang) {
        this.notifyError("error ao salvar! nenhum conteúdo foi adicionado!");
        return;
      }
      Object.entries(game.langs).forEach(([key, value]) => {
        console.log(key);
        if (value.download_url.length == 0) {
          this.notifyError("error ao salvar! link de download ausente!");
          pass = false;
        }
      });
      if (pass) {
        this.gameSettings.saveButtonActive = false;
        let res = await this.axios.post(settings.APIRoutes.saveGame(), {
          game: JSON.stringify(game),
          token: utils.getCookie(settings.cookieAuthToken),
        });
        this.gameSettings.saveButtonActive = true;
        if (res.data.status) {
          let unshift = false;
          if (this.gameEdit.id == 0) {
            unshift = true;
            this.notifySuccess("Jogo salvo!");
          } else {
            this.notifySuccess("Jogo atualizado!");
          }
          this.gameEdit.id = res.data.game_id;
          if (unshift) {
            this.games.unshift(this.safeCopyObject(game));
            this.selectGame(game.id);
          }
        } else {
          this.notifyError("Error ao salvar o jogo");
        }
      }
    },
    uploadLogo(e) {
      let files = this.$refs.logoFile.files;
      console.log(files);
      if (e) {
        e.preventDefault();
        if (e.dataTransfer.files) {
          files = e.dataTransfer.files;
        }
      }
      if (
        files[0] == null ||
        files.length > 1 ||
        files.length == 0 ||
        !this.isFilesImage(files)
      ) {
        this.$toast.open({
          message: "arquivo para upload da logo invalido!",
          duration: 6 * 1000,
          position: "bottom",
          type: "error",
        });
        return;
      }
      if (typeof files[0] != "undefined") {
        let formData = new FormData();
        formData.append("file", files[0]);
        this.gameEdit.langs[this.gameSettings.currentLanguage].logo = "";
        this.gameSettings.uploadingLogo = true;
        this.uploadImages(formData).then((data) => {
          this.gameSettings.uploadingLogo = false;
          if (data.upload) {
            let image = data.images[0].filename;
            this.gameEdit.langs[this.gameSettings.currentLanguage].logo = image;
          } else {
            this.errorUploadImageNotify();
          }
        });
      }
    },
    uploadPrints(e) {
      let files = this.$refs.printFiles.files;
      if (e) {
        e.preventDefault();
        if (e.dataTransfer.files) {
          files = e.dataTransfer.files;
        }
      }
      if (files[0] == null || files.length == 0 || !this.isFilesImage(files)) {
        this.$toast.open({
          message: "arquivos invalido!",
          duration: 6 * 1000,
          position: "bottom",
          type: "error",
        });
        return;
      }
      let lastIndexItem =
        this.gameEdit.langs[this.gameSettings.currentLanguage].prints.length;
      lastIndexItem = lastIndexItem == -1 ? 0 : lastIndexItem;
      if (typeof files[0] != "undefined") {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("file", files[i]);
          this.gameEdit.langs[this.gameSettings.currentLanguage].prints.push(
            ""
          );
        }
        this.gameSettings.uploadingPrints = true;
        this.uploadImages(formData).then((data) => {
          this.gameSettings.uploadingPrints = false;
          if (data.upload) {
            for (let i in data.images) {
              this.gameEdit.langs[this.gameSettings.currentLanguage].prints[
                parseInt(i) + lastIndexItem
              ] = data.images[i].filename;
            }
          } else {
            this.errorUploadImageNotify();
          }
        });
      }
    },
    async uploadImages(imagesData) {
      let response = await this.axios.post(
        settings.APIRoutes.uploadImages(),
        imagesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: utils.getCookie(settings.cookieAuthToken),
          },
        }
      );
      if (response.status == 200) {
        return response.data;
      } else {
        this.errorUploadImageNotify();
      }
    },
    notifySuccess(msg) {
      this.$toast.open({
        message: msg,
        duration: 5 * 1000,
        position: "bottom",
        type: "success",
      });
    },
    notifyError(msg) {
      this.$toast.open({
        message: msg,
        duration: 5 * 1000,
        position: "bottom",
        type: "error",
      });
    },
    errorUploadImageNotify() {
      this.$toast.open({
        message: "erro ao enviar as imagem(s)! tente novamente!",
        duration: 6 * 1000,
        position: "bottom",
        type: "error",
      });
    },
    loadUsersStatistics() {},
  },
};
