<template>
   <div class="margin_left cards_game games_reviews_container">
      <div class="card_game_header">
        <div class="card_game_name">
            Últimos Reviews
        </div>
      </div>
      <div class="content custom_scroll">
        <div class="item_list" v-for="review in reviews" v-bind:key="review.id">
          <div class="top_item_list">
            <div class="item_image profile_image">
              <img v-if="review.hasImage" :class="review.hasImage" :src="review.user_image" >
              <div v-else  :style="{'background': getColorIndex(review.rating_id)}">{{review.user_name[0]}}</div>
            </div>
            <div class="list_infos_container">
              <div class="review_header">
                <div class="review_user_name">{{review.user_name}}</div>
                <star-rating
                  v-model:rating="review.stars"
                  read-only="true"
                  :padding="4"
                  :show-rating="false"
                  :border-width="2"
                  border-color="#ffd055"
                  inactive-color="#1d1d1d"
                  class="rating"
                  star-size="11"/>
              </div>
              <div class="review">
                {{review.comment}}
              </div>
            </div>
            <div class="item_image game_cover">
               <img :src="getCover(review.game_logo)" >
            </div>
          </div>
          <div class="bottom_item_list">
            <div class="review_time">{{review.timeAgo}}</div>
            <div class="game_title">{{review.game_title}}</div>
          </div>
        </div>
      </div>
   </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import settings from "../settings";
import utils from "../utils";
import { useTimeAgo } from '@vueuse/core'

export default {
  components: {
    StarRating
  },
  setup() {},
  data(){
    return{
      colors: ["#06B359","#180AE0","#D10870","#DA1414","#C49A05","#5814B8"],
      usersColors: [],
      rating: 3,
      reviews: []
    }
  },
  mounted() {
    this.getReviews();
  },
  methods: {
    getColorIndex(){
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    getCover(img){
      return settings.APIHost+"/image/"+img
    },
    async getReviews(){
      let res = await this.axios.post(settings.APIRoutes.getReviews(),{
        token: utils.getCookie(settings.cookieAuthToken),
        });
      if(res){
        res.data.forEach(function(e, i) {
          res.data[i].hasImage = false;
          res.data[i].timeAgo = useTimeAgo(new Date(e.date))
          if(e.user_image != "null"){
            res.data[i].hasImage = true;
          }
        });
        this.reviews = res.data;
      }
      setTimeout(() => {
        this.getReviews()
      }, 30*1000);
    }
  }
}
</script>

<style scoped>
.cards_game{
  padding-right: 3px;
}
  .rating{
    position: relative;
    top: 7px;
    position: relative;
    left: 4px;
    float: right;
  }
  .games_reviews_container{
    width: 440px !important;
  }
  .content{
    display: grid;
    position: relative;
    width: 100%;
    margin-top: 5px;
    overflow-y: scroll;
    height: 215px;
  }
  .item_list{
     background: var(--background);
     border-radius: 8px;
     margin-bottom: 5px;
  }
  .top_item_list{
    width: 100%;
    display: flex;
  }
  .item_image{
    width: 55px;
    height: 55px;
    background: var(--background0);
    position: relative;
    top: 5px;
    margin-left: 5px;
    border-radius: 8px;
  }
  .game_cover{
    width: 68px;
    height: 68px;
    overflow: hidden;
  }
  .game_cover img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .list_infos_container{
    width: calc(100% - 143px);
    position: relative;
    margin-left: 5px;
  }
  .bottom_item_list{
    position: relative;
    width: calc(100% - 83px);
    margin-left: 5px;
    height: 10px;
    font-size: 9.9px;
    color:#777777;
    display: grid;
    grid-template-columns: 30% 70%;
  }
  .game_title{
    text-align: end;
    position: relative;
    top: -7px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .review_time{
    position: relative;
    left: 2px;
    top: -5px;
  }
  .review_header{
    width: 100%;
    height: 28px;
  }
  .review_user_name{
    width: calc(100% - 100px);
    position: relative;
    float: left;
    font-size: 13px;
    color:var(--textColor);
    margin-top: 9px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .review{
    color:var(--textColor);
    font-size: 12px;
    position: relative;
    margin-bottom: 12px;
  }
  .profile_image{
    overflow: hidden;
  }
  .profile_image img{
    width: 100%;
    height: 100%;
  }
   .profile_image div{
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
</style>


