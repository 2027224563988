<template>
  <div class="button" :class="{disable:!active}" v-on:click="onClick">
    <span v-if="!loading">{{name}}</span>
    <Loading v-if="loading" :size="'26px'" class="loading"/>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
export default {
  props:{
    name:{
      type:String,
      default:"",
    },
    active: {
      type: Boolean,
      default: true,
    },
    loading:{
      type:Boolean,
      default: false
    }
  },
  components:{
    Loading,
  },
  methods:{
    onClick(){
      if(this.active){
        this.$emit("ontap");
      }
    }
  },
}
</script>

<style scoped>
  .loading{
    margin-top:3.5px;
  }
  .button{
    width: 100%; height: 33px;
    text-align: center;
    line-height: 33px;
    background: var(--theme);
    border-radius: 18px;
    position: relative;
    margin-top: 10px;
    color: #2e2e2e;
    font-weight: bold;
    font-size: 15px;
    transition: .2s linear;
    user-select: none;
    cursor: pointer;
  }
  .disable{
    background: #ccc;
  }
  .button:active{
    background: #ccc;
  }
</style>